export const isJavascript = (task) => {

    if (
        isJavascriptString(task.title, 'title') ||
        isJavascriptString(task.description, 'description')
    ) {
        return true;
    } else {
        return false;
    }
}